import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getProducts } from '../products/products.actions';
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Get as Show } from '../products/products.container';
import Paper from '@mui/material/Paper';
import { loadStripe } from '@stripe/stripe-js';
import { fetchCheckoutSession } from '../stripe/stripe.actions';
import { Modal } from '@material-ui/core';
import Box from '@mui/material/Box';
import { Parse } from 'parse';
import * as R from 'ramda';

const style = {
  position: 'fixed',
  transform: 'translate(-50%, -50%)',
  top: '10%',
  left: '30%',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export function UserNewStripeEmbed() {
  const dispatch = useDispatch();
  let products = useSelector((state) => state.products.data);
  let stripeSession = useSelector((state) => state.stripe.data);
  let [accountPlan, setAccountPlan] = useState(null);
  let [activeProducts, setActiveProducts] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const stripePromise = loadStripe(
    process.env[`REACT_APP_STRIPE_${process.env.REACT_APP_STAGE}_KEY`]
  );
  const [userParams, setUserParams] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [passwordError, setPasswordError] = useState(true);
  const [emailError, setEmailError] = useState(true);

  const yearlyLabel = isYearly
    ? 'Yearly(Yay!!! you Get Two Months Free)'
    : 'Yearly(Toggle to get 2 Months Free)';
  const backgroundColors = ['#E6E2F7', '#F9F0E1', '#BFE2EB'];
  const requiredFields = ['name', 'username', 'email', 'company'];

  const accountPlanChanged = (plan) => {
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams.accountPlan = plan;
    setUserParams(newParams);
    setAccountPlan(plan);
    setModalOpen(true);
  };

  useEffect(() => {
    const newParams = Object.assign({}, userParams);
    dispatch(fetchCheckoutSession(newParams));
  }, [accountPlan]);

  const fieldChangeHandler = (e, field) => {
    setHasError(false);
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams[field] = e.target.value;
    setUserParams(newParams);
    if (passwordError || emailError) {
      setDisabled(true);
    } else {
      setDisabled(!hasRequiredFields(newParams));
    }
    if (!disabled) {
      dispatch(fetchCheckoutSession(newParams));
    }
  };

  const hasRequiredFields = (obj) => {
    // Check if the object is null or undefined
    if (!obj) {
      return false;
    }

    // Check if all required fields have values in the object
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (!(field in obj) || obj[field] === '' || R.empty(obj[field])) {
        return false;
      }
    }

    // All required fields have values in the object
    return true;
  };

  const handlePasswordError = () => {
    if (userParams.password !== userParams.passwordConfirmation) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleClick = async () => {
    try {
      // Call your backend to create the Checkout session.
      const user = new Parse.User();
      //const blackList = ["passwordConfirmation", "passwordError", "emailError", "disabled", "submitError", "open"];
      const newParams = Object.assign({}, userParams);
      delete newParams.passwordConfirmation;
      delete newParams.apiKey;
      dispatch(fetchCheckoutSession(newParams));
      await user.save(newParams, {});
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: stripeSession.id });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };

  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    if (userParams && userParams.email) {
      validateEmail(userParams.email)
        ? setEmailError(false)
        : setEmailError(true);
    }
  }, [userParams]);

  useEffect(() => {}, [accountPlan]);

  useEffect(() => {
    if (!products) {
      return;
    }
    const activeProducts = products.filter((product) => product.active);
    for (let i = 0; i < activeProducts.length; i++) {
      const product = activeProducts[i];
      product.color = backgroundColors[i];
    }

    setActiveProducts(activeProducts.reverse());
  }, [products]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx {...style}>
          <Card>
            <CardContent>
              <TextField
                required
                id="Name"
                label="Name: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'name')}
              />
              <TextField
                required
                id="username"
                label="Username: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'username')}
              />

              <TextField
                required
                id="email"
                label="Email: "
                fullWidth
                defaultValue=""
                type={'email'}
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'email')}
              />
              <FormHelperText
                error={emailError}
                style={{ visibility: emailError ? 'visible' : 'hidden' }}
              >
                The email you entered is invalid
              </FormHelperText>

              <TextField
                required
                id="password"
                label="Password: "
                type="password"
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                variant="outlined"
                error={passwordError}
                name="password"
                onChange={(change) => fieldChangeHandler(change, 'password')}
                onBlur={handlePasswordError.bind(this)}
              />
              <TextField
                required
                id="password"
                label="Password Confirmation: "
                type="password"
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                variant="outlined"
                onChange={(change) =>
                  fieldChangeHandler(change, 'passwordConfirmation')
                }
                error={passwordError}
                name="passwordConfirmation"
                onBlur={handlePasswordError.bind(this)}
              />
              <FormHelperText
              // error={this.state.passwordError}
              //style={{visibility: passwordVisibilityString}}>The passwords do not match
              ></FormHelperText>
            </CardContent>
            <CardContent>
              <TextField
                required
                id="company"
                label="Company: "
                fullWidth
                rows="3"
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'company')}
              />

              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={disabled}
                onClick={() => handleClick()}
              >
                Submit
              </Button>
            </CardContent>
            <CardContent>
              <FormHelperText
                error={hasError}
                style={{ visibility: hasError ? 'visible' : 'hidden' }}
              >
                {errorMessage}
              </FormHelperText>
            </CardContent>
            <CardContent>
              <Button href="dashboard" color="primary">
                Already A User? Sign in here.
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Paper sx={{ padding: '22px' }}>
        <Grid container direction={'column'} spacing={3} alignItems={'center'}>
          <Grid item justifySelf={'flex-start'} xs={6}>
            <Typography sx={{ margin: '12px' }} variant={'h4'}>
              Get Started by choosing a plan
            </Typography>
          </Grid>
          <FormControlLabel
            control={
              <Switch
                checked={isYearly}
                onChange={() => setIsYearly(!isYearly)}
              />
            }
            label={yearlyLabel}
          />
          <Grid item container direction={'row'} xs={12}>
            <Grid item xs={12}>
              {activeProducts && activeProducts.length > 0 && (
                <Grid container item spacing={4} justifyContent={'center'}>
                  {activeProducts.map(
                    (product, key) =>
                      product.active && (
                        <Grid item xs={4} key={key}>
                          {key !== 0 ? (
                            <Show
                              backgroundColor={backgroundColors[key]}
                              setPlan={accountPlanChanged}
                              isYearly={isYearly}
                              product={product}
                            />
                          ) : (
                            <Show
                              backgroundColor={backgroundColors[key]}
                              setPlan={accountPlanChanged}
                              isYearly={isYearly}
                              product={product}
                              bestSeller={true}
                            />
                          )}
                        </Grid>
                      )
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
