import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QRCode } from 'lifetaggerQrCode';
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Tooltip, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import qrGenerator from 'qrcode-generator';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save'; // Save Icon for downloading QR code
import Remove from './beacon.remove';
import { Modal } from '@material-ui/core';

function ShowQrCode(props) {
  const { qrCode, direction, size, justifyContent, deleteButton, history } =
    props;
  const theme = useTheme();
  const ref = useRef();
  const gridSize = qrCode.type == 'beacon' ? 12 : 6;
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
        );
        const shortenedUrl = await response.text();
        setUrl(shortenedUrl);
      } catch (e) {
        console.log('error');
      }
    }
    fetchData();
  }, [props.qrCode]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    history.push(qrCode.type == 'beacon' ? '/beacons' : '/qrcode');
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((err) => console.error('Failed to copy text: ', err));
  };

  const handleSaveClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const saveAsImage = (format) => {
    const downloadSize = 1000; // Define the desired download resolution

    // Create an off-screen container
    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.top = '-9999px';
    document.body.appendChild(offScreenContainer);

    // Render a high-resolution QRCode component off-screen
    ReactDOM.render(
      <QRCode
        value={url}
        size={downloadSize} // High resolution size
        ecLevel={'Q'}
        enableCORS={true}
        bgColor={qrCode.bgColor || '#f2f2f2'}
        logoImage={qrCode.image || '/images/Layer_2@3x.png'}
        fgColor={qrCode.fgColor || '#262626'}
        logoWidth={40 * (downloadSize / 122)}
        removeQrCodeBehindLogo={false}
        qrStyle={qrCode.qrStyle || 'dots'}
        eyeRadius={qrCode.eyeRadius || 0}
      />,
      offScreenContainer,
      () => {
        // Access the canvas from the rendered QRCode instance after rendering
        setTimeout(() => {
          const canvas = offScreenContainer.querySelector('canvas');
          if (canvas) {
            const link = document.createElement('a');
            link.download = `${qrCode.name || 'qrcode'}-highres.${format}`;
            link.href =
              format === 'png'
                ? canvas.toDataURL('image/png')
                : canvas.toDataURL('image/jpeg', 0.92); // Adjust quality for JPEG
            link.click();
          }

          // Clean up the off-screen container
          ReactDOM.unmountComponentAtNode(offScreenContainer);
          document.body.removeChild(offScreenContainer);
        }, 300);
      }
    );
  };

  return (
    <div>
      <Modal
        open={open}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Remove
          beacon={qrCode}
          handleClose={handleDelete}
          remove={props.remove}
        />
      </Modal>
      <Grid
        container
        direction={direction}
        justifyContent={justifyContent}
        sx={theme.custom.lifeCodeDivBox}
        spacing={1}
      >
        {qrCode.type != 'beacon' && (
          <Grid item xs={5.5} sx={theme.custom.lifeCodeDivBox} ref={ref}>
            <QRCode
              value={url}
              ecLevel={'Q'}
              logoImage={qrCode.image || '/images/Layer_2@3x.png'}
              fgColor={qrCode.fgColor || '#262626'}
              logoWidth="30"
              enableCORS={true}
              bgColor={qrCode.bgColor || '#f2f2f2'}
              removeQrCodeBehindLogo={false}
              eyeRadius={qrCode.eyeRadius || 0}
              size={size}
              qrStyle={qrCode.qrStyle || 'dots'}
            />
          </Grid>
        )}
        <Grid item xs={gridSize}>
          <Grid
            container
            direction={'column'}
            justifyContent={'flex-end'}
            flexDirection={'column'}
            spacing={2}
          >
            <Grid item xs={12}>
              <div style={theme.custom.typography.subTitleV2}>
                {qrCode.name}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={theme.custom.typography.descriptionTitle}>
                {qrCode.description}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container direction={'column'} spacing={2}>
              {qrCode.type == 'beacon' && (
                <Grid
                  item
                  xs={12}
                  alignSelf={'center'}
                  sx={{ marginTop: '5px' }}
                >
                  <div style={theme.custom.typography.descriptionTitle}>
                    {qrCode.beaconId}
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                alignSelf={'flex-end'}
                justifySelf={'flex-end'}
              >
                {!deleteButton ? (
                  <Button
                    size={'small'}
                    startIcon={<EditIcon />}
                    sx={theme.custom.typography.subTitleV2}
                    onClick={() =>
                      history.push('/qrcode/details/' + qrCode.beaconId)
                    }
                  >
                    <div style={theme.custom.typography.subTitleV2}>Edit</div>
                  </Button>
                ) : (
                  <Button
                    size={'small'}
                    startIcon={<DeleteForeverIcon />}
                    onClick={handleOpen}
                    sx={theme.custom.typography.caption}
                  >
                    <div style={theme.custom.typography.caption}>Delete</div>
                  </Button>
                )}

                {/* New Copy URL Button */}
                <Tooltip title={copySuccess ? 'Copied!' : 'Copy URL'} arrow>
                  <Button
                    size={'small'}
                    startIcon={<ContentCopyIcon />}
                    onClick={handleCopy}
                    sx={theme.custom.typography.subTitleV2}
                  >
                    <div style={theme.custom.typography.subTitleV2}>
                      Copy URL
                    </div>
                  </Button>
                </Tooltip>

                {/* New Save Button */}
                <Button
                  size={'small'}
                  startIcon={<SaveIcon />}
                  sx={theme.custom.typography.subTitleV2}
                  onClick={handleSaveClick}
                >
                  <div style={theme.custom.typography.subTitleV2}>Save</div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => saveAsImage('png')}>
                    Save as PNG
                  </MenuItem>
                  <MenuItem onClick={() => saveAsImage('jpg')}>
                    Save as JPG
                  </MenuItem>
                  {/* <MenuItem onClick={saveAsSVG}>Save as SVG</MenuItem> */}
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ShowQrCode;
