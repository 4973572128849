import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

export function Show(props) {
  const { product, backgroundColor, isYearly, setPlan } = props;
  const defaultPrice = isYearly
    ? product.prices.find((price) => price.id != product['default_price'])
    : product.prices.find((price) => price.id == product['default_price']);
  const backgroundStyle = { backgroundColor: backgroundColor };
  const metaData = Object.entries(product.metadata)
    .map(([key, value]) => {
      return { key, value };
    })
    .sort((a, b) => {
      if (a.value != 'false' && b.value == 'false') {
        return -1;
      }
      if (a.value == 'false' && b.value != 'false') {
        return 1;
      }

      if (a.key.includes('Everything included')) {
        return -1;
      }

      return 0;
    });

  return (
    <Card sx={{ ...backgroundStyle, borderRadius: '12px' }}>
      <CardContent sx={{ ...backgroundStyle }}>
        <Typography variant={'subtitle1'}>{product.name}</Typography>
        <Typography sx={{ marginBottom: '7px' }} variant={'caption'}>
          {product.description}
        </Typography>
        <Typography sx={{ marginTop: '10px' }} variant={'h5'}>
          {`$${defaultPrice.unit_amount_decimal.slice(
            0,
            defaultPrice.unit_amount_decimal.length - 2
          )}.${defaultPrice.unit_amount_decimal.slice(
            defaultPrice.unit_amount_decimal.length - 2
          )}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{ width: '100%', backgroundColor: 'black' }}
          variant={'contained'}
          size="small"
          onClick={() => setPlan(defaultPrice.id)}
        >
          Get Started
        </Button>
      </CardActions>
      {product.metadata && (
        <CardContent>
          {metaData.map((metadata, key) => {
            return (
              <Grid container key={key}>
                <Grid item xs={12}>
                  {metadata.key.includes('Everything included') ? (
                    <div>
                      <Typography
                        variant={'subtitle1'}
                        sx={{ textAlign: 'center', color: '#BF2420' }}
                      >
                        <b>{metadata.key}</b>
                      </Typography>
                      <div style={{ height: '10px' }} />
                    </div>
                  ) : (
                    <Typography variant={'subtitle2'}>
                      {metadata.key}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!metadata.key.includes('Everything included') && (
                    <Typography variant={'caption'}>
                      <blockquote>{metadata.value}</blockquote>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </CardContent>
      )}
    </Card>
  );
}
